import { createRouter, createWebHistory } from 'vue-router'
import Password from '../views/passwd.vue'
import Admin from '../views/Admin.vue'
import Tarif from '../views/Tarif.vue'
import QuestionReponse from '../views/QuestionReponse.vue'
import Commander from '../views/Commander.vue'
import PaymentValide from '../views/PaymentValide.vue'
import Notvalide from '../views/Notvalide.vue'
import CGV from '../views/CGV.vue'
import GestionCartons from '../components/GestionCarton.vue'
import GestionCodePromo from "@/components/GestionCodePromo.vue";
import GestionUser from "@/components/GestionUser.vue";
import AdminCarton from "@/views/AdminCarton.vue";
import AdminUser from "@/views/AdminUser.vue";
import AdminCodePromo from "@/views/AdminCodePromo.vue";

const routes = [
  {
    path: '/',
    name: 'passwd',
    component: Password
  },
  {
    path: '/tarif',
    name: 'Tarif',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Tarif
  },
  {
    path: '/question-reponse',
    name: 'QuestionReponse',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: QuestionReponse
  },
  {
    path: '/commander',
    name: 'commander',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Commander
  },
  {
    path: '/paymentValide',
    name: 'PaymentValide',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: PaymentValide
  },
  {
    path: '/notvalide',
    name: 'Notvalide',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Notvalide
  },
  {
    path: '/admin',
    name: 'Admin',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: Admin
  },
  {
    path: '/admin/cartons',
    name: 'AdminCarton',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AdminCarton
  },
  {
    path: '/admin/code-promo',
    name: 'AdminCodePromo',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AdminCodePromo
  },
  {
    path: '/admin/utilisateur',
    name: 'AdminUser',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: AdminUser
  },
  {
    path: '/CGV',
    name: 'CGV',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: CGV
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: "/"
},
 
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
