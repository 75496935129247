<script setup>

</script>

<template>
<nav>
  <router-link to="/admin/">Commandes</router-link>
  <router-link to="/admin/cartons">Cartons</router-link>
  <router-link to="/admin/code-promo">Code Promo</router-link>
  <router-link to="/admin/utilisateur">Utilisateur</router-link>
  <router-link class="btn" to="/tarif">Retour site client</router-link>
</nav>
</template>

<style scoped lang="scss">
nav {
  height: 10vh;
  background-color: #39B8F1;
  width: 100%;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  font-family: 'Ubuntu', sans-serif;;
  font-weight: bold;
}
.btn{
  background-color: #b4cc73;
  padding: 15px;
  border-radius: 10px;
}
</style>