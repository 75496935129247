<template>
  <div class="add">
    <h2>Ajout d'utilisateur</h2>
    <div class="form">
      <div class="same-row">
        <div class="col">
          <label for="motDePasse">Mot de passe</label>
          <input
              @change="checkVerif(addUser.passWord)"
              :class="{ 'isBad': (check_passWord) }"
              v-model="addUser.passWord"
              required
              type="text"
          />
        </div>
        <div class="col">
          <label for="enseigne">Enseigne</label>
          <input
              @change="checkVerif(addUser.enseigne)"
              :class="{ 'isBad': !check_enseigne }"
              v-model="addUser.enseigne"
              type="text"
          />
        </div>
      </div>
      <div class="same-row">
        <div class="col">
          <label for="telephone">Telephone</label>
          <input
              @change="checkVerif(addUser.tel)"
              :class="{ 'isBad': !check_tel }"
              v-model="addUser.tel"
              type="text"
          />
        </div>
        <div class="col">
          <label for="mail">Mail</label>
          <input
              @change="checkVerif(addUser.mail)"
              :class="{ 'isBad': !check_mail }"
              v-model="addUser.mail"
              type="text"
          />
        </div>
      </div>
      <div class="same-row">
        <div class="col">
          <label for="adresse">Adresse</label>
          <input
              @change="checkVerif(addUser.adresse)"
              :class="{ 'isBad': !check_adresse }"
              v-model="addUser.adresse"
              type="text"
          />
        </div>
        <div class="col">
          <label for="ville">Ville</label>
          <input
              @change="checkVerif(addUser.ville)"
              :class="{ 'isBad': !check_ville }"
              v-model="addUser.ville"
              type="text"
          />
        </div>
      </div>
      <div class="same-row">
        <div class="col">
          <label for="codePostal">Code Postal</label>
          <input
              @change="checkVerif(addUser.CP)"
              :class="{ 'isBad': !check_CP }"
              v-model="addUser.CP"
              type="text"
          />
        </div>
        <div class="col">
          <label for="pays">Pays</label>
          <input
              @change="checkVerif(addUser.pays)"
              :class="{ 'isBad': (check_pays) }"
              v-model="addUser.pays"
              type="text"
          />
        </div>
      </div>
      <div class="checkbox">
        <label for="isMultiUsage">mettre l'utilisateur à usage multiple</label>
        <input
            @change="checkVerif(addUser.isMultiUsage)"
            v-model="addUser.isMultiUsage"
            type="checkbox"
            id="isMultiUsage"
        />
      </div>
      <div class="checkbox">
        <label for="isAdmin">administrateur</label>
        <input v-model="addUser.isAdmin" type="checkbox" id="isAdmin" disabled />
      </div>
    </div>
    <button @click="ajouter()">Ajouter</button>
  </div>
</template>

<script>
export default {
  name: 'AddUser',
  data() {
    return {
      addUser: {
        passWord: '',
        enseigne: '',
        tel: '',
        mail: '',
        adresse: '',
        ville: '',
        CP: '',
        pays: '02France',
        isMultiUsage: false,
        isAdmin: false
      },
      errors: []
    }
  },
  methods: {
    // TODO: Improve error handling
    gestionErreurs(ctx) {
      switch (ctx) {
        case this.addUser.passWord:
          if (this.addUser.passWord.length === 0) {
            this.errors.push('Password cannot be empty');
          }
          break;
          // Add similar checks for other fields (enseigne, tel, etc.)
        default:
          break;
      }
    },
    ajouter() {
      const auth = require('../../api').getCookie('adminAuth');

      const myHeaders = new Headers();
      myHeaders.append("Authorization", "@Bearer " + auth);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("passWord", this.addUser.passWord);
      urlencoded.append("enseigne", this.addUser.isMultiUsage ? '' : this.addUser.enseigne);
      urlencoded.append("tel", this.addUser.isMultiUsage ? '' : this.addUser.tel);
      urlencoded.append("mail", this.addUser.isMultiUsage ? '' : this.addUser.mail);
      urlencoded.append("adresse", this.addUser.isMultiUsage ? '' : this.addUser.adresse);
      urlencoded.append("ville", this.addUser.isMultiUsage ? '' : this.addUser.ville);
      urlencoded.append("codePostal", this.addUser.isMultiUsage ? '' : this.addUser.CP);
      urlencoded.append("pays", this.addUser.isMultiUsage ? '' : this.addUser.pays);
      urlencoded.append("isAdmin", this.addUser.isAdmin);
      urlencoded.append("multiUsage", this.addUser.isMultiUsage);

      const requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://www.adventure-pro.fr/api/users/register/", requestOptions)
          .then(response => response.json())
          .then(result => {
            if (!result.error) {
              window.location.replace("/admin");
            } else {
              this.gestionErreurs(this.addUser.passWord);
              this.emit('error-state', this.errors);
            }
          })
          .catch(error => console.log('error', error));
    },
    checkVerif(ctx) {
      console.log(ctx);
    },
    computed: {
      check_pasrWord() {
        return this.addUser.passWord === null || this.addUser.passWord === '';
      },
      check_pays() {
        return this.addUser.pays === null || this.addUser.pays === '';
      },
      check_enseigne() {
        return this.addUser.isMultiUsage ? this.addUser.enseigne.length === 0 : this.addUser.enseigne.length > 0;
      },
      check_tel() {
        return this.addUser.isMultiUsage ? this.addUser.tel.length === 0 : this.addUser.tel.length > 0 && this.addUser.tel.match(/^((\+33|0)_1-)](\d{2}){4}/);
      },
      check_mail() {
        return this.addUser.isMultiUsage ? this.addUser.mail.length === 0 : this.addUser.mail.length > 0 && this.addUser.mail.match(/^\[^\\s@\]\+@\[^\\s@\]\+\\\.\[^\\s@\]/);
      },
      check_adresse() {
        return this.addUser.isMultiUsage ? this.addUser.adresse.length === 0 : this.addUser.adresse.length > 0 && this.addUser.adresse.match(/^((\+33|0)[1-9](\d{2}){4}$)/);
      },
      check_ville() {
        return this.addUser.isMultiUsage ? this.addUser.ville.length === 0 : this.addUser.ville.length > 0;
      },
      check_CP() {
        return this.addUser.isMultiUsage ? this.addUser.CP.length === 0 : this.addUser.CP.length > 0 && this.addUser.CP.match(/^(?:0[1-9][1-9]\d|9[0-8]\d|90[0-2])\d{2}$/);
      },
      check_isAdmin() {
        return this.addUser.isMultiUsage ? this.addUser.isAdmin.length === 0 : this.addUser.isAdmin.length > 0;
      }
    }}}
</script>


<style lang="scss" scoped>
@import "../../scss/_variables.scss";
.add {
background-color: white;
display: flex;
flex-direction: column;
justify-content: center;
align-items: center;
border-radius: 20px;
margin: 0px auto;

button {
margin-bottom: 30px;
}
}

.checkbox {
display: flex;
justify-content: space-evenly;
align-items: center;
margin: 30px 0;
}

.same-row {
display: flex;
flex-direction: row;
flex-wrap: wrap;
margin-top: 10px;
justify-content: center;
align-items: center;
}

.col {
display: flex;
flex-direction: column;
justify-content: center;
margin: 5px 10px;

label {
margin: 5px 0;
font-weight: bolder;
}

input {
padding: 7px;
border-radius: 20px;
border: solid 2px;
font-size: $fontsize;
color: $fontcolor;
outline: none;

&::placeholder {
color: $grey;
font-weight: bold;
}

&:focus-visible {
border: solid 2px $focuscolor;
}
}
}

.col.isBad {
  border: solid $grey 2px;
}

.col.isCorrect {
  border: solid $fancy 2px;
}

button {
  padding: 5px 10px;
  font-size: 10px;
}
</style>