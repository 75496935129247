<template>
  <div class="question-reponse">
    <h1>Questions / Réponses</h1>
    <Question question="Contact" reponse="">
      <p class="contact">
        <Icon icon="ic:round-call" height="1.25em" style="margin-right: 10px"/>
        <span>07 71 70 79 22</span></p>
      <p class="contact">
        <Icon icon="material-symbols:mail-rounded" height="1.25em" style="margin-right: 10px"/>
        <span>contact@aventbirthday-pro.fr</span></p>
    </Question>
    <Question question="Quelles sont les DLUO des calendriers ?"
              reponse="Les dates limites de consommation optimum s'échelonnent entre 14 et 18 mois selon nos fournisseurs. En général les commandes sont expédiées avec une DLUO de 12/15 mois selon les calendriers."/>
    <Question question="Quelle est la commande minimum de calendriers ?"
              reponse="La commande minimum est de 12 calendriers"/>
    <Question question="À quoi correspond l'offre à 10€ ?" reponse="">
      <p>En facturation mensuelle, cette offre est parfaitement adaptée aux structures effectuant peu d'anniversaires ou
        qui ne possèdent pas les locaux permettant le stockage des produits ou qui ne veulent pas se charger de leur
        gestion.</p>
      <p>Nous proposons d'envoyer directement le calendrier au domicile de l'enfant qui va fêter son anniversaire (envoi
        en lettre suivie 5€22 inclus dans le prix).</p>
      <p>Vous avez juste à nous fournir le nom, adresse et niveau scolaire de l'enfant et nous nous chargeons de
        l'expédition (le numéro de suivi de l'envoi vous sera notifié par retour de mail).</p>
    </Question>
    <Question question="Qu'est ce que la marque blanche pour mon activité ?" reponse="">
      <p>Le concept Aventbirthday peut-être exploité en marque blanche, sous le nom de votre enseigne (contrat de
        concession de droits d'auteur <a class="fancy" href="copyright-aventbirthday.pdf" target="_blank">copyright
          N°1132570</a>, Adventus Evenire auteur V.Guicheteau).</p>
      <p>Les calendriers sont alors produits à votre logo avec la possibilité de personnaliser les questions/réponses
        ainsi que le contenu.</p>
      <p class="contact">
        <Icon icon="material-symbols:mail-rounded" height="1.25em" style="margin-right: 10px"/>
        <span>contact@aventbirthday-pro.fr</span></p>
    </Question>
    <Question question="Comment passer une commande ?"
              reponse="rendez-vous dans l'onglet 'commander' choisir la quantité de calendriers et suivre les étapes unes à unes.">
      <button @click="redirectPage('commander')">Commander</button>
    </Question>
    <Question question="J'ai choisi 'payer par virement' comment valider ma commande ?"
              reponse="Votre commande ne sera traitée à réception du paiement utilisez les coordonnées bancaires ci-dessous:">
      <p>
        Votre commande ne sera traitée à réception du paiement. Les coordonnées bancaires vous seront envoyées par mail
        après validation de votre commande.
      </p>
      <p>
        Sinon, contactez-nous au 07 71 70 79 22.
      </p>
    </Question>
    <Question question="Quel est le délai pour réceptionner ma commande ?"
              reponse="Votre commande est traitée dans les plus brefs délais dès réception du paiement. L'expédition est assurée partout en France par transporteur en 72h au lieu de réception de votre choix."/>
    <Question question="Les Colis sont-ils modulables ?"
              reponse="Oui, vous ajoutez à votre commande les calendriers dont vous avez besoin parmi la gamme de 7 calendriers."/>
    <Question question="Conditions Générales de vente" reponse="">
      <button @click="redirectPage('CGV')">Consulter</button>
    </Question>
    <Question question="Propriété intellectuelle" reponse="">
      <p>Propriété intellectuelle : Le concept dénommé « AventBirthday » est intrinsèque à la création
        littéraire et/ou artistique de M. Vincent Guicheteau qui est intitulée « Adventus Evenire » (Éditions
        USD System – Collection « Passeport Intellectuel CB » N° VG1943FR160421. La réalisation technique
        et/ou commerciale des procédés et savoir-faire du concept – obligeant la production, la reproduction
        © et l'interprétation de tout ou partie des textes et des dessins qui sont consignés dans l'ouvrage sus
        désigné – est constitutive des produits qui sont exposés dans ce site Internet, ainsi que des projets
        commerciaux et économiques qui s'y rattachent.
        <br/>
        <a class="fancy" href="copyright-aventbirthday.pdf" target="_blank">
          Certificat copyright N°1132570</a>
        <br/>Rappel : Du seul fait de la création de son œuvre dont il est l'unique propriétaire, l'auteur détient
        l'exclusivité mondiale de tout ou partie de sa production, de sa reproduction © et de son
        interprétation et ce, relativement à l'exploitation commerciale du concept matérialisé qui en résulte.
        Conformément aux conventions internationales et aux lois internes des États sur le droit d'auteur,
        cette exclusivité est mondialement reconnue pour toute la vie de l'auteur ainsi que cinquante à
        soixante-dix ans après son décès et ce, selon la législation de chaque État.
        Tout le contenu du site web est et reste la propriété intellectuelle et exclusive de la SARL
        AVENTBIRTHDAY. Tous les droits lui sont réservés. Personne n'est autorisé à reproduire, exploiter,
        rediffuser ou utiliser à quelque titre que ce soit, même partiellement, des éléments du site qu'ils
        soient logiciels, visuels ou sonores. Tous ces éléments sont protégés.
        <br/>Tout lien simple ou par hypertexte est strictement interdit sans un accord écrit exprès de la société
        AVENTBIRTHDAY.
        <br/>« AVENTBIRTHDAY » est une marque enregistrée à l'INPI le 7 juin 2015, sous le numéro 4386256.</p>
    </Question>
  </div>
</template>

<script>
import {Icon} from "@iconify/vue";
import Question from '../components/Question.vue'

export default {
  name: 'Question-reponse',
  data() {
    return {}
  },
  components: {
    Icon,
    Question
  },
  methods: {
    redirectPage(page) {
      window.location.replace("/" + page);
    }
  },
  created() {
    const api = require('../../api')
    api.checkCookie()
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.fancy {
  color: $fancy;
}

.question-reponse {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  min-height: 100vh;

  h1 {
    margin: 50px auto;
  }
}

.contact {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;

  span {
    margin-left: 10px;
  }
}

button {
  background-color: $fancy;
  color: $blanchatre;
}
</style>