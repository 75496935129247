<template>
  <div class="gestion-user">
    <table>
      <caption>Gestion des utilisateurs</caption>
      <thead>
      <th>Mot de passe</th>
      <th>Dernière connection</th>
      <th>Enseigne</th>
      <th>Tel</th>
      <th>Mail</th>
      <th>Adresse</th>
      <th>Ville</th>
      <th>CodePostal</th>
      <th>Usage multiple</th>
      <th>Id</th>
      <th></th>
      <th></th>
      </thead>
      <tbody>
      <tr v-for="user in users" :key="user">
        <td data-label="Mot de passe" v-if="user.id != rowToEdit.row">{{ user.passWord }}</td>
        <td data-label="Dernière connection" v-if="user.id != rowToEdit.row">{{ new Date(user.lastConnect).toLocaleString() }}</td>
        <td
            data-label="Enseigne"
            v-if="user.id != rowToEdit.row"
        >{{ (user.enseigne == null || user.enseigne == '') ? ' - ' : user.enseigne }}</td>
        <td
            data-label="Tel"
            v-if="user.id != rowToEdit.row"
        >{{ (user.tel == null || user.tel == '') ? ' - ' : user.tel }}</td>
        <td
            data-label="Mail"
            v-if="user.id != rowToEdit.row"
        >{{ (user.mail == null || user.mail == '') ? ' - ' : user.mail }}</td>
        <td
            data-label="Adresse"
            v-if="user.id != rowToEdit.row"
        >{{ (user.adresse == null || user.adresse == '') ? ' - ' : user.adresse }}</td>
        <td
            data-label="Ville"
            v-if="user.id != rowToEdit.row"
        >{{ (user.ville == null || user.ville == '') ? ' - ' : user.ville }}</td>
        <td
            data-label="Code Postal"
            v-if="user.id != rowToEdit.row"
        >{{ (user.codePostal == null || user.codePostal == '') ? ' - ' : user.codePostal }}</td>
        <td data-label="Usage multiple" v-if="user.id != rowToEdit.row" class="multiusage">
          <Icon v-if="user.isMultiUsage" class="green" icon="ic:round-check-circle"/>
          <Icon v-if="!user.isMultiUsage" class="red" icon="ic:baseline-close"/>
        </td>
        <td data-label="Id" v-if="user.id != rowToEdit.row">{{ user.id }}</td>
        <td data-label="Modifier" v-if="user.id != rowToEdit.row">
          <button @click="Edith(user)">
            <Icon icon="ic:outline-mode-edit" />
          </button>
        </td>
        <td data-label="Supprimer" v-if="user.id != rowToEdit.row">
          <button @click="supprimer(user)">
            <Icon icon="ic:baseline-delete-forever" />
          </button>
        </td>

        <td data-label="Mot de passe" v-if="user.id == rowToEdit.row">{{ user.passWord }}</td>
        <td data-label="Dernière connection" v-if="user.id == rowToEdit.row">{{ new Date(user.lastConnect).toLocaleString() }}</td>
        <td data-label="Enseigne" v-if="user.id == rowToEdit.row">
          <input type="text" v-model="rowToEdit.enseigne" />
        </td>
        <td data-label="Tel" v-if="user.id == rowToEdit.row">
          <input type="text" v-model="rowToEdit.tel" />
        </td>
        <td data-label="Mail" v-if="user.id == rowToEdit.row">
          <input type="text" v-model="rowToEdit.mail" />
        </td>
        <td data-label="Adresse" v-if="user.id == rowToEdit.row">
          <input type="text" v-model="rowToEdit.adresse" />
        </td>
        <td data-label="Ville" v-if="user.id == rowToEdit.row">
          <input type="text" v-model="rowToEdit.ville" />
        </td>
        <td data-label="Code Postal" v-if="user.id == rowToEdit.row">
          <input type="text" v-model="rowToEdit.codePostal" />
        </td>
        <td data-label="Usage multiple" v-if="user.id == rowToEdit.row" class="multiusage">
          <input type="checkbox" v-model="rowToEdit.isMultiUsage" />
        </td>
        <td data-label="Id" v-if="user.id == rowToEdit.row">{{ user.id }}</td>
        <td v-if="user.id == rowToEdit.row">
          <button @click="update()">Ok</button>
        </td>
        <td v-if="user.id == rowToEdit.row"></td>
      </tr>
      </tbody>
    </table>
  </div>
</template>
<script>
import {Icon} from "@iconify/vue";

export default {
  name: 'GestionUser',
  data() {
    return {
      users: [],
      rowToEdit: {
        row: 0,
        enseigne: '',
        tel: '',
        mail: '',
        adresse: '',
        ville: '',
        codePostal: '',
        isMultiUsage: true
      },
      addUser: {
        passWord: '',
        enseigne: '',
        tel: '',
        mail: '',
        adresse: '',
        ville: '',
        CP: '',
        pays: '',
        isMultiUsage: true,
        isAdmin: false
      }
    }
  },
  methods: {
    Edith(user) {
      let rowToEdit = {
        row: user.id,
        enseigne: (user.enseigne == null) ? '' : user.enseigne,
        tel: (user.tel == null) ? '' : user.tel,
        mail: (user.mail == null) ? '' : user.mail,
        adresse: (user.adresse == null) ? '' : user.adresse,
        ville: (user.ville == null) ? '' : user.ville,
        codePostal: (user.codePostal == null) ? '' : user.codePostal,
        isMultiUsage: (user.isMultiUsage == null) ? true : user.isMultiUsage
      }
      this.rowToEdit = rowToEdit
    },
    update() {
      const api = require('../../api')
      let auth = api.getCookie('adminAuth')

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + auth);
      myHeaders.append("Content-Type", "application/x-www-form-urlencoded");

      var urlencoded = new URLSearchParams();
      urlencoded.append("enseigne", this.rowToEdit.enseigne);
      urlencoded.append("tel", this.rowToEdit.tel);
      urlencoded.append("mail", this.rowToEdit.mail);
      urlencoded.append("adresse", this.rowToEdit.adresse);
      urlencoded.append("ville", this.rowToEdit.ville);
      urlencoded.append("codePostal", this.rowToEdit.codePostal);
      urlencoded.append("isMultiUsage", this.rowToEdit.isMultiUsage);

      var requestOptions = {
        method: 'PUT',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://www.aventbirthday-pro.fr/api/users/update/" + this.rowToEdit.row, requestOptions)
          .then(response => response.text())
          .then(result => {
            if (!result.error) {
              this.rowToEdit = {
                row: 0,
                enseigne: '',
                tel: '',
                mail: '',
                adresse: '',
                ville: '',
                codePostal: '',
                isMultiUsage: ''
              }
              window.location.replace("/admin");
            }
          })
          .catch(error => console.log('error', error));
    },
    supprimer(user) {
      const api = require('../../api')
      let auth = api.getCookie('adminAuth')

      var myHeaders = new Headers();
      myHeaders.append("Authorization", "Bearer " + auth);

      var urlencoded = new URLSearchParams();

      var requestOptions = {
        method: 'DELETE',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow'
      };

      fetch("https://www.aventbirthday-pro.fr/api/users/" + user.id, requestOptions)
          .then(response => response.text())
          .then(() => window.location.replace("/admin"))
          .catch(error => console.log('error', error));
    },
    errorState(e) {
      this.$emit('error-state', e)
    }
  },
  computed: {
    checkPasswd() {
      return this.addUser.passWord == null || this.addUser.passWord == ''
    }
  },
  components: {
    Icon
  },
  created() {
    const api = require('../../api')
    let auth = api.getCookie('adminAuth')
    var myHeaders = new Headers();
    myHeaders.append("Authorization", "Bearer " + auth);

    var requestOptions = {
      method: 'GET',
      headers: myHeaders,
      redirect: 'follow'
    };

    fetch("https://www.aventbirthday-pro.fr/api/users", requestOptions)
        .then(response => response.json())
        .then(result => {
          this.users = result
        })
        .catch(error => console.log('error', error));
  }
}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

.gestion-user {
  background-color: $blanchatre;
  padding: 20px;
  margin: 0 auto;
  border-radius: 10px;
  caption {
    font-size: $fontsizeH2;
    margin: 20px auto 50px auto;
  }
}

table {
  width: 100%;
  background-color: $fancy;
  border-radius: 5px;
  overflow-x: auto;
  thead th {
    padding: 5px;
  }
  tbody tr {
    text-align: center;
    td {
      margin: 2px 3px;
      padding: 0 5px;
      border-radius: 2px;
      background: white;

      button {
        padding: 5px 10px;
        font-size: $fontsize;
      }
    }
  }
}

.multiusage {
  .green {
    color: green;
  }
  .red {
    color: red;
  }
}

@media screen and (max-width: 1250px) {
  .gestion-user {
    width: 90%;
    padding: 5px;
  }
  table {
    border: 0;
  }

  table thead {
    border: none;
    clip: rect(0 0 0 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  table tr {
    border-bottom: 3px solid #ddd;
    margin-bottom: .625em;
    overflow: auto;
  }

  table td {
    border-bottom: 1px solid #ddd;
    display: block;
    font-size: $fontsize;
    text-align: right;
  }

  table td::before {
    content: attr(data-label);
    float: left;
    font-weight: bold;
    text-transform: uppercase;
  }

  table td:last-child {
    border-bottom: 0;
  }
}
</style>