<template>
  <div id="OrderDetail">
    <div class="top">
      <button @click="$emit('close')">
        <Icon icon="ic:baseline-close"/>
      </button>
      <h1>
        Commande n° {{order.id}}
      </h1>
      <h1>
        de {{order.enseigne}}
      </h1>
      <h1>fait le {{new Date(order.createdAt).toLocaleString()}}</h1>
    </div>
    <div class="main">
      <div class="section">
        <div>
          <h1> Detail </h1>
          <table>
            <tr>
              <td>
                Carton
              </td>
              <td>
                {{order.detail.carton.unit}}
              </td>
            </tr>
            <tr>
              <td>
                Paiment
              </td>
              <td>
                {{order.detail.payment}}
              </td>
            </tr>
            <tr>
              <td>
                Status
              </td>
              <td>
                {{order.detail.status}}
              </td>
            </tr>
            <tr>
              <td>
                HT
              </td>
              <td>
                {{order.detail.HT}} €
              </td>
            </tr>
            <tr>
              <td>
                TTC
              </td>
              <td>
                {{order.detail.TTC}} €
              </td>
            </tr>
            <tr>
              <td>
                TVA
              </td>
              <td>
                {{order.detail.TVA}} €
              </td>
            </tr>
            <tr>
              <td colspan="2">
                CODE REDUCTION : {{order.detail.reduct.code}}
              </td>
            </tr>
            <tr>
              <td>
                Montant : {{order.detail.reduct.montant}}
              </td>
              <td>
                 Pourcent : {{order.detail.reduct.pourcent}}
              </td>
            </tr>
          </table>
        </div>
        <div>
          <h2> Composition </h2>
          <table>
            <tr>
              <th>
                Produit
              </th>
              <th>
                Quantité
              </th>
            </tr>
            <tr v-for="product in order.detail.products.filter(e => e.count > 0)">
              <td>
                {{product.name}}
              </td>
              <td>
                {{product.count}}
              </td>
            </tr>
          </table>
        </div>
      </div>
      <div class="section">
        <div>
          <h2> Information Livraison </h2>
          <table>
            <tr><td>Nom</td><td>{{order.livraison.nom}}</td></tr>
            <tr><td>Prénom</td><td>{{order.livraison.prenom}}</td></tr>
            <tr><td>Téléphone</td><td>{{order.livraison.tel}}</td></tr>
            <tr><td>Mail</td><td>{{order.livraison.mail}}</td></tr>
            <tr><td>Adresse 1</td><td>{{order.livraison.addresse1}}</td></tr>
            <tr><td>Adresse 2</td><td>{{order.livraison.addresse2}}</td></tr>
            <tr><td>Code Postal</td><td>{{order.livraison.CP}}</td></tr>
            <tr><td>Ville</td><td>{{order.livraison.ville}}</td></tr>
            <tr><td colspan="2">Adresse postale</td></tr>
            <tr><td colspan="2">{{order.livraison.addresse1}}<span v-if="order.livraison.addresse2"> - {{order.livraison.addresse2}}</span>, {{order.livraison.CP}} {{order.livraison.ville}} </td></tr>
          </table>
        </div>
      </div>
      <div class="section">
        <div>
          <h2> Information Facturation </h2>
          <table>
            <tr><td>Nom</td><td>{{order.facturation.nom}}</td></tr>
            <tr><td>Prénom</td><td>{{order.facturation.prenom}}</td></tr>
            <tr><td>Téléphone</td><td>{{order.facturation.tel}}</td></tr>
            <tr><td>Mail</td><td>{{order.facturation.mail}}</td></tr>
            <tr><td>Adresse 1</td><td>{{order.facturation.addresse1}}</td></tr>
            <tr><td>Adresse 2</td><td>{{order.facturation.addresse2}}</td></tr>
            <tr><td>Code Postal</td><td>{{order.facturation.CP}}</td></tr>
            <tr><td>Ville</td><td>{{order.facturation.ville}}</td></tr>
            <tr><td colspan="2">Adresse postale</td></tr>
            <tr><td colspan="2">{{order.facturation.addresse1}}<span v-if="order.facturation.addresse2"> - {{order.facturation.addresse2}}</span>, {{order.facturation.CP}} {{order.facturation.ville}} </td></tr>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Icon} from "@iconify/vue";

export default {
  name: 'OrderDetail',
  components: {Icon},
  props: ['order'],
  data() {
    return {
      clicked: false
    }
  },
  computed: {
    selected() {
      return this.clicked
    }
  }
}
</script>

<style scoped lang="scss">
#OrderDetail {
  overflow-y: auto;
  position: fixed;
  width: 90%;
  height: 80vh;
  margin: auto;
  top: 5vh;
  background-color: #faf6f6;
  border-radius: 20px;
  padding: 20px;
  z-index: 20;
  box-shadow: 10px 5px 5px #545454;
  button {
    background: #2c3e50;
  }
  .top {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
  }
  .main {
    margin-top: 20px;
    display: flex;
    flex-flow: row wrap;
    .section {
      margin: 5px;
      display: flex;
      flex-flow: row wrap;
      justify-content: space-evenly;
      align-items: start;
      flex: 1;
    }
  }
  table {
    background-color: #2c3e50;
    td, th {
      padding: 10px;
      background-color: #CDE3ED;
    }
  }
}
</style>