<template>
    <div class="Choix-carton">
        <h2>Choisissez le volume</h2>
        <div class="cartons">
            <Cartoncard
                @UpCarton="updateCarton(carton)"
                v-for="carton in cartons"
                :key="carton"
                :carton="carton"
            ></Cartoncard>
        </div>
        <hr />
        <div class="fact">
            <div class="content">
                <div v-if="DisplayCartonMultiple" class="nombre">
                    <h2>Nombre</h2>
                    <Selector :items="arrNb" selectedChoice="1" @newVal="updateQuantite" />
                </div>
                <div class="facture">
                    <h2>Facturation</h2>
                    <p>
                        <span>Quantitées :</span>
                        <span>{{ quantite }}</span>
                    </p>
                    <p>
                        <span>Montant HT :</span>
                        <span>{{ montantHT.toFixed(2) }} €</span>
                    </p>
                    <p>
                        <span>Frais de ports :</span>
                        <span>{{ montantPort }}</span>
                    </p>
                    <p v-if="reduction">
                        <span>Reduction :</span>
                        <span>{{ reduct.pourcent }} %</span>
                    </p>
                    <p>
                        <span>TOTAL HT :</span>
                        <span>{{ TotalHT.toFixed(2) }} €</span>
                    </p>
                    <p>
                        <span>TVA (20%) :</span>
                        <span>{{ montantTVA }} €</span>
                    </p>
                    <p>
                        <span>TOTAL TTC :</span>
                        <span>{{ totalTTC }} €</span>
                    </p>
                </div>
            </div>
        </div>
        <div class="sub">
            <button :disabled="!isOneSelected" @click="Next">Suivant</button>
        </div>
    </div>
</template>

<script>
import Cartoncard from '../components/Carton-card.vue'
import Selector from '../components/Selector.vue'
export default {
    name: 'Choix-carton',
    components: {
        Cartoncard,
        Selector
    },
    data() {
        return {
            isOneSelected: false,
            quantite: 0,
            reduct: {
                code:'',
                montant:0,
                pourcent:0,
                min:1
            },
            cartonSelected: {
                id: -1,
                name: 'default',
                img: 'carton-1.png',
                unit: 0,
                prixUnit: 0,
                quantiteMax: 1,
                port: 0
            },
            cartons: [],
            reductions: []
        }
    },
    methods: {
        updateCarton(e) {
            this.cartonSelected = e
            this.quantite = 1
            this.isOneSelected = true
        },
        updateQuantite(e) {
            this.quantite = e
        },
        calculReduc() {
            let reduct = {
                code:'',
                montant:0,
                pourcent:0,
                min:1
            }
            for (let i = 0; i < this.reductions.length; i++) {
                const element = this.reductions[i];
                if (element.min <= this.cartonSelected.unit * this.quantite) {
                    if (reduct.pourcent < element.pourcent) {
                        reduct=element
                    }
                }
            }
            this.reduct.code=reduct.code
            this.reduct.montant=reduct.montant
            this.reduct.pourcent=reduct.pourcent
            this.reduct.min=reduct.min
        },
        Next() {
            if (this.isOneSelected) {
                let data = {
                    carton: this.cartonSelected,
                    quantite: this.quantite * this.cartonSelected.unit,
                    reduct: this.reduct,
                    HT: this.montantHT,
                    TVA: Number(this.montantTVA),
                    TTC: Number(this.totalTTC)
                }
                this.$emit('Validation', data)
            }
        }
    },
    computed: {
        DisplayCartonMultiple() {
            return this.cartonSelected.quantiteMax > 1
        },

        arrNb() {
            let val = this.cartonSelected.quantiteMax
            let arr = []
            for (let i = 0; i < val; i++) {
                arr.push((i + 1).toString())
            }
            return arr
        },
        montantHT() {
            return ((this.cartonSelected.prixUnit * this.cartonSelected.unit) * this.quantite)
        },
        TotalHT() {
            this.calculReduc()
            let reduction = (this.reduction) ? (1 - (this.reduct.pourcent / 100)) : 1
            return (this.montantHT+ this.cartonSelected.port) * (reduction)
        },
        totalTTC() {
            return (this.TotalHT * 1.2).toFixed(2)
        },
        montantTVA() {
            return (this.TotalHT * 0.2).toFixed(2)
        },
        montantPort() {
            return (this.cartonSelected.port == 0) ? 'inclus' : this.cartonSelected.port + ' €'
        },
        reduction() {
            return this.reduct.pourcent > 0
        },
    },
    created() {
        const api = require('../../api')
     api.checkCookie()

    let auth = api.getCookie('authentification')

  
    var requestOptions = {
      method: 'GET',
      headers: {
        "Authorization": "Bearer "+auth
      },
      redirect: 'follow'
    };

    fetch("https://www.aventbirthday-pro.fr/api/cartons", requestOptions)
      .then(response => response.json())
      .then(result => this.cartons = result)
      .catch(error => console.log('error', error));

      fetch("https://www.aventbirthday-pro.fr/api/codepromo/reducpannier", requestOptions)
      .then(response => response.json())
      .then(result => this.reductions = result)
      .catch(error => console.log('error', error));
    }
    


}
</script>

<style lang="scss" scoped>
@import "../../scss/_variables.scss";

button {
    margin: 50px 0;
}
.fact {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 80%;
    background-color: white;
    border-radius: 30px;
    .content {
        display: flex;
        flex-flow: row;
        justify-content: space-evenly;
        width: 100%;
        .nombre {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 200px;
        }
        .facture {
            width: 50%;
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: center;
            p {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: center;
            }
        }
    }
}
.cartons {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    justify-content: center;
}
.Choix-carton {
    margin: 50px auto;
    width: 70%;
    background-color: $blanchatre;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-evenly;
    border-radius: 30px;
    padding: 5px;
    padding-bottom: 20px;
    hr {
        margin: 30px auto;
        width: 50%;
        height: 2px;
        border: none;
        background: $grey;
    }
    h2 {
        margin: 30px 0;
    }
}
@media (max-width: 850px) {
    .Choix-carton {
        width: 90%;
    }
    .fact .content {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        .facture {
            width: 90%;
            margin: 0 auto;
        }
    }
}
</style>